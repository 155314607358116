import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import SimpleLoader from "../components/common/Loaders/SimpleLoader";
import { VerticalSep } from "../components/styled/Gaps";
import EmailIcons from "../assets/email.svg";
import PhoneIcons from "../assets/phone.svg";
import {
  API_URL,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  DEVICE,
} from "../utilities/constants";

const HelpHeader = styled.div`
  margin-bottom: 26px;
  font-size: 24px;
  color: #0099cc;
  font-weight: 600;
`;

const HelpContainer = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  padding: 48px;
`;

const HelpSubHeader = styled.div`
  font-size: 20px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 48px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
`;

const CardData = styled.div`
  padding: 20px 24px 40px;
`;
const CardTitle = styled.div`
  background-color: #0099cc;
  color: #fff;
  padding: 12px 24px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 12px 12px 0px 0px;
`;

const ContactInfo = styled.div`
  border-radius: 12px;
  background-color: #ebf7fb;
  width: 624px;
  height: 224px;
`;

const ContactName = styled.div`
  font-size: 20px;
  color: #0099cc;
  margin-bottom: 20px;
`;

const ContactEmail = styled.div`
  font-size: 16px;
  color: #808080;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  img {
    margin-right: 10px;
    width: 16px;
  }
`;

const ContactNumber = styled.div`
  font-size: 16px;
  color: #808080;
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
    width: 16px;
  }
`;

const Help = () => {
  return (
    <>
      <HelpHeader>Help</HelpHeader>
      <HelpContainer>
        <HelpSubHeader>
          For any enquiries or feedback, please do reach us.
        </HelpSubHeader>
        <ContentContainer>
        
          <ContactInfo>
            <CardTitle>India Region</CardTitle>
            <CardData>
              <ContactName>Mr. Krishnan Kannan</ContactName>
              <ContactEmail>
                <img src={EmailIcons} />
                kannan.krishnan@hemehealth.io
              </ContactEmail>
              <ContactNumber>
                <img src={PhoneIcons} />
                +91-9322414058
              </ContactNumber>
            </CardData>
          </ContactInfo>
   
        </ContentContainer>


        <ContentContainer></ContentContainer>
      </HelpContainer>
    </>
  );
};

export default Help;
